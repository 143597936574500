import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { MiscUtil } from 'app/common/utility/miscUtil';

@Injectable({
    providedIn: 'root'
})
export class AuthInitService {
    constructor(private msalService: MsalService) { }

    initialize(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            await this.msalService.instance.initialize().catch((error) => {
                reject(error);
            });
            this.msalService.instance.handleRedirectPromise()
                .then((result: AuthenticationResult | null) => {
                    if (result) {
                        this.msalService.instance.setActiveAccount(result.account);
                        localStorage.setItem('authToken', result.idToken);
                        resolve();
                    } else {
                        const accounts = this.msalService.instance.getAllAccounts();
                        const uid = MiscUtil.getQueryStringValue('uid');
                        const account = accounts.find((acc) => acc.username === uid || acc.idTokenClaims?.email === uid || acc.idTokenClaims?.preferred_username === uid || acc.idTokenClaims?.upn === uid || acc.idTokenClaims?.unique_name === uid);
                        const request = {
                            scopes: ['email', 'openid', 'profile', 'offline_access'],
                            loginHint: uid,
                        };
                        if (!uid) {
                            request['prompt'] = 'select_account';
                        }
                        if (account) {
                            this.msalService.instance.setActiveAccount(account);
                            this.msalService.instance.acquireTokenSilent({
                                scopes: ['email', 'openid', 'profile', 'offline_access'],
                                account: account
                            }).then((result: AuthenticationResult) => {
                                localStorage.setItem("authToken", result.idToken);
                                resolve();
                            }).catch(() => {
                                this.msalService.instance.loginRedirect(request);
                            });
                        } else {
                            this.msalService.instance.loginRedirect(request);
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
